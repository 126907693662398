<template>
	<div class="appointmentList-view">
		<div class="flex-a-c">
			<el-input type="text" placeholder="请输入管理员姓名" v-model="keyword" style="width: 240px" show-word-limit>
			</el-input>
			<el-button class="v-primary ml10" type="primary" @click="getPageDepartment">搜索</el-button>
			<el-button class="v-primary ml10" type="primary" @click="editDepartment('add')" :disabled="!hasPermi">新增管理员
			</el-button>
		</div>

		<commonTable :tableData="tableData" @handleSizeChange="handleSizeChange"
			@handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :loading="loading" :total="total">
			<template v-slot:table>
				<el-table-column prop="name" align="center" label="姓名" />
				<el-table-column prop="departmentName" width="250" align="center" label="负责部门" />
				<el-table-column prop="state" align="center" label="权限">
					<template slot-scope="scope">
						<div class="flex-c-c">

							<!-- <div class="cblue hover_pointer" @click="checkAll(scope.row)" v-if="scope.row.member">
                  点击查看
              </div>
              <p class="cblue" v-else>无</p> -->
							<div class="cblue hover_pointer" @click="checkAll(scope.row)">
								点击查看
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="empStatus" align="center" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.empStatus">
            </el-switch>
          </template>
        </el-table-column> -->
				<el-table-column prop="state" width="270" align="center" label="操作">
					<template slot-scope="scope">
						<el-button :disabled="!hasPermi" type="text" style="color: #51cbcd;margin-right:10px"
							@click="editDepartment('edit',scope.row)">
							编辑
						</el-button>
						<el-button :disabled="!hasPermi" type="text" style="color: #FD634E;margin-right:10px"
							@click="dissolve(scope.row.empId)">
							删除
						</el-button>

					</template>
				</el-table-column>
			</template>
		</commonTable>
		<!-- 查看所有成员 -->
		<el-dialog class="label_dialog" title="查看权限" width="30%" center :visible.sync="checkVisible"
			:modal-append-to-body="false" @closed="checkVisible = false" :close-on-click-modal="false">
			<div class="" v-if="memberData">
				<el-tree :data="treeData" ref="tree" show-checkbox :props="defaultProps" node-key="id"></el-tree>
				<div class="d-flex justify-content-center">
					<el-button class="v-primary ml10" @click="checkVisible = false" type="primary"
						:disabled="!hasPermi">确认</el-button>
				</div>
			</div>

		</el-dialog>


	</div>
</template>

<script>
	import commonTable from "@/components/common/commonTable";
	import {
		fetchProductsTypeList,
		queryPageManager,
		deletedManager
	} from "@/api/companyManage.js";
	import {
		queryCompanyStaff
	} from "@/api/articleManagement";
	import {
		getDataTimeSec
	} from "@/utils";
	import Path from '@antv/g2/lib/geometry/path';
	import userRoot from '@/utils/root'
	import {
		mapState
	} from 'vuex';

	export default {
		name: "appointmentList",
		components: {
			commonTable,
		},
		data() {
			return {
				hasPermi: true,
				keyword: '',
				optionList: "", //全部分类
				tableData: [],
				staffList: [],
				total: 0, //总条数
				currentPage: 1, //当前页数
				pageNum: 1,
				pageSize: 10,
				loading: false, //加载中
				checkVisible: false, //新增部门开关
				memberData: '', //成员数据
				treeData: [],
				defaultProps: {
					children: 'children',
					label: 'label'
				},

			};
		},
		computed: {
			...mapState(['routerArr']),
		},
		created() {
			if (JSON.parse(localStorage.getItem('isSourceAdmin'))) {
				this.hasPermi = false
			}
		},
		watch: {
			routerArr(val) {
				this.jurisdiction = val.some(item => ['02100'].includes(item)) //部门管理(所有)
				if (this.jurisdiction) {
					this.getPageDepartment()
				} else {
					this.getPageDepartment()
				}
			}
		},

		mounted() {
			console.log('userRoot', userRoot)
			this.traverse(this.treeData)
			this.getPageDepartment()
		},
		methods: {
			// //请求管理员信息
			getPageDepartment() {

				this.loading = true;
				let data = {
					pageNum: this.pageNum,
					pageSize: this.pageSize,
					name: this.keyword,
					userId: this.jurisdiction ? this.$store.state.loginRoot.userInfo.userId : ''
				};
				queryPageManager(data)
					.then((res) => {
						this.loading = false;
						this.tableData = res.data.pageInfo.list || "";
						this.total = res.data.pageInfo.total;
						console.log(this.tableData)
					})
					.catch((err) => {
						this.tableData = [];
						this.loading = false;
						console.log(err);
					});
			},
			//请求预约数据
			// getTableData() {
			//   this.loading = true;
			//   let data = {
			//     pageNum: this.pageNum,
			//     pageSize: this.pageSize,
			//   };
			//   queryCompanyStaff({ companyId: 0 })
			//   .then((res) => {
			//     console.log(res)
			//     this.loading = false;
			//     this.tableData = res.data.pageInfo.list || "";
			//     this.total = res.data.pageInfo.total;
			//     console.log('this.tableData',this.tableData)
			//   })
			//   .catch((err) => {
			//     this.loading = false;
			//     this.tableData = [];
			//     console.log(err);
			//   });
			// },

			traverse(obj) {
				let arr = []
				for (var a in obj) {
					if (obj[a].children) {
						this.traverse(obj[a]); //递归遍历
					} else {
						if (obj[a].isSelect) {
							arr.push(obj[a].id)
						}

					}
				}
				console.log(arr)
			},

			//查询分类
			getTypeList() {
				fetchProductsTypeList({
						ignore: true
					})
					.then((res) => {
						this.optionList = res.data;
					})
					.catch((err) => {
						console.log(err);
					});
			},
			//查看所有
			checkAll(lsitData) {

				let than = this
				let data = JSON.parse(JSON.stringify(userRoot.userRoot))
				if (!lsitData.routers) {
					return this.$message({
						message: "暂未分配权限",
						type: "warning",
					});
				}
				data.map((item, index) => {
					item.disabled = true

					if (item.children) {

						item.children.map((v, k) => {
							v.disabled = true
							if (v.children) {
								v.children.map((v1, k1) => {
									v1.disabled = true
									if (v1.children) {
										v1.children.map((v2, k2) => {
											v2.disabled = true
										})
									}
								})
							}
						})
					}

				})
				this.treeData = data
				this.memberData = lsitData
				try {
					lsitData.routers = JSON.parse(lsitData.routers)
				} catch (e) {}
				console.log(JSON.stringify(lsitData))
				than.$nextTick(() => {
					than.$refs.tree.setCheckedKeys(lsitData.routers)
				})
				this.checkVisible = true
			},
			//解散部门
			dissolve(id) {
				this.$confirm("是否确认删除该管理员，删除后不可恢复。", "提示", {
						onConfirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "info ",
					})
					.then(() => {
						let data = {
							empId: id
						}
						deletedManager(data)
							.then(res => {
								this.$message({
									message: "删除成功",
									type: "success",
								});
								this.getPageDepartment()
							})

					})
					.catch((err) => {});
			},
			//编辑成员
			editDepartment(type, item) {
				if (type == 'add') {
					this.$router.push({
						path: 'AdministratorDetail'
					})
				} else {
					let id = item.empId
					this.$router.push({
						path: '/ent/AdministratorList?id=' + id
					})
				}


			},
			//全选
			selectedAll() {
				let selectedAllState = this.staffList.findIndex(target => target.isSelect === false) == -1
				if (selectedAllState) {
					this.staffList.forEach(item => {
						item.isSelect = false
					})
				} else {
					this.staffList.forEach(item => {
						item.isSelect = true
					})
				}
			},
			//当前页变更
			handleCurrentChange(num) {
				this.pageNum = num;
				this.getTableData();
			},

			//当前页查询数据条数变更
			handleSizeChange(num) {
				this.pageSize = num;
				this.pageNum = 1;
				this.currentPage = 1;
				this.getTableData();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.appointmentList-view {
		background: #ffffff;
		padding: 24px;
	}

	.member-box {
		padding: 5px 10px;
		background: #51CDCB;
		display: flex;
		justify-content: center;
		align-items: center;
		font: 14px;
		color: #ffffff;
		margin-right: 10px;
		margin-left: 10px;
		border-radius: 3px;
	}

	.more-box {
		padding: 5px 10px;
		background: #FD634E;
		display: flex;
		justify-content: center;
		align-items: center;
		font: 14px;
		color: #ffffff;
		border-radius: 3px;
	}

	.member-scroll {
		max-height: 300px;
		overflow-y: auto;
	}
</style>
